import {bindable, customElement, inject} from 'aurelia-framework';
import {FormServiceFactory} from '../form/service/form-service-factory';
import {UserClient} from "../api/user-client";
import 'lodash.product';
import * as _ from "lodash";
import {DialogService} from "aurelia-dialog";
import {EditDimensionsDialog} from "./edit-dimensions-dialog";
import Sortable from "sortablejs";
import Client from "../api/client";
import {DimensionConfig} from "./dimension-config";
import {ChangePriceDialog} from "./change-price-dialog";
import {ChangeSeasonDialog} from "./change-season-dialog";
import moment from "moment-timezone";

@customElement('price-set-input-new')
@inject(FormServiceFactory, UserClient, DialogService, Client, DimensionConfig)
export class PriceSetInputNew {

    purchaseCurrencyConfig = {
        label: 'price.purchaseCurrency',
        required: true,
        set: 'currency',
        translateChoiceLabel: false,
    };

    retailCurrencyConfig = {
        label: 'price.retailCurrency',
        required: true,
        set: 'currency',
        translateChoiceLabel: false,
    };

    taxPercentageConfig = {
        label: 'sio.field.taxPercentage',
        required: true,
    };

    manualFactorConfig = {
        label: 'price.manualFactor',
        help: 'price.manualFactor-help'
    };

    purchasePriceModeConfig = {
        label: 'price.purchasePrices',
        help: 'price.purchasePricesHelp'
    };

    retailPriceModeConfig = {
        label: 'price.retailPrices',
        help: 'price.retailPricesHelp'
    };

    lines = [];
    specialLines = [];
    specialDimensions = [];

    purchasePriceMode;
    retailPriceMode;

    priceModes = [
        {
            value: 'none',
            label: 'sio.field.none',
        },
        {
            value: 'factor',
            label: 'sio.field.factor',
            help: 'sio.field.factorHelp',
        },
        {
            value: 'perPerson',
            label: 'sio.field.perPerson',
            help: 'sio.field.perPersonHelp',
        },
        {
            value: 'perRoom',
            label: 'sio.field.perRoom',
            help: 'sio.field.perRoomHelp',
        },
        {
            value: 'perGroup',
            label: 'sio.field.perGroup',
            help: 'sio.field.perGroupHelp',
        },
    ];

    showStatus;
    showFreePlaces;
    showManualFactorField;
    showRetailPrices;
    predefinedSeasons;

    manualFactor;
    taxPercentage;
    purchaseCurrency = 'EUR';
    retailCurrency = 'EUR';

    constructor(formServiceFactory, user, dialogService, client, dimensionConfig) {
        this.formServiceFactory = formServiceFactory;
        this.dialogService = dialogService;
        this.client = client;
        this.dimensionConfig = dimensionConfig;

        const settings = user.getUser()?.organization?.settings?.priceSettings;
        const defaultPriceMode = settings?.defaultPriceType ?? 'purchasePrice';

        this.purchasePriceMode = (defaultPriceMode === 'both' || defaultPriceMode === 'purchasePrice') ? 'perPerson' : 'none';
        this.retailPriceMode = (defaultPriceMode === 'both' || defaultPriceMode === 'retailPrice') ? 'perPerson' : 'none';

        this.showFreePlaces =  !(settings.hideFreePlaces ?? false);
        this.showStatus =  !(settings.hideStatus ?? false);
        this.showManualFactorField = !(settings.hideManualFactor ?? false);

        this.showRetailPrices = !(settings.hideRetailPrices ?? false);

        this.predefinedSeasons = settings.predefinedSeasonTexts ?? [];
    }

    @bindable field;
    @bindable({defaultBindingMode: 2}) value;

    _getControlUID() {
        return 'price-set-input-' + this.field.property;
    }

    async bind() {

        this.calculationColumns = this.field.formService?.form?.params?.calculationColumns;

        let formValue = this.field.formService.getValue();
        this.context = formValue.context ?? this.field.formService.config.contextObjectRef;

        //Weird case when duplicating
        if (this.context?.contextObjectRef) {
            this.context = this.context.contextObjectRef;
        }

        if (this.context?.modelId === 'price/contingent-pool') {
            this.context = (await this.client.get('price/contingent-pool/' + this.context.id, 60)).context;
        } else if (this.context?.modelId === 'price/price-group') {
            this.context = (await this.client.get('price/price-group/' + this.context.id, 60)).accommodations[0];
        }

        const context = this.context ? (await this.client.get(this.context.modelId + '/' + this.context.id, 60)) : null;

        let country = (context?.address?.country != null && context?.address?.country != '' ? context?.address?.country : 'DEFAULT');

        this.taxPercentageConfig.choices = (await this.client.get('accounting/tax-rates/fetch/' + country, 60));

        this.taxPercentage = this.taxPercentageConfig.choices[0].value;

        let result = (await this.client.get('tourism/country?conditions[countryCode]=' + country, 60));

        if (result?.items?.[0]?.currencyCode) {
            this.purchaseCurrency = result?.items?.[0]?.currencyCode;
            this.retailCurrency = result?.items?.[0]?.currencyCode;
        }

        if (this.context) {
            this.context = {
                id: context.id,
                modelId: context.modelId
            };
        }

        this.field.setValue(this._processModelValue());
    }

    attached() {
        this.initializeSorting();
    }

    initializeSorting() {
        let element = document.getElementById(this._getControlUID());

        if (!element) {
            return;
        }

        this.sortable = Sortable.create(element, {
            handle: '.glyphicon-menu-hamburger',
            forceFallback: true,
            onEnd: this.onEnd.bind(this)
        });
    }

    onEnd(evt) {
        this.specialLines.move(evt.oldIndex, evt.newIndex);

        this.updateAndSaveValue();
    }

    valueChanged(newValue) {

        //Important check here, as a view change also triggers this callback and this way we would have a loop
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        this._processModelValue();
    }

    _processModelValue() {
        let lines = [];
        let specialLines = [];
        let specialDimensions = {};
        const dimensions = {};

        for (let i = 0; i < this.value?.priceLines.length; i++) {

            const line = _.clone(this.value.priceLines[i]);
            const {purchasePrice, retailPrice} = line;

            if (purchasePrice) {
                this.purchaseCurrency = purchasePrice.currency;
                line.purchasePrice = this.dimensionConfig._convertToView(purchasePrice.amount, purchasePrice.currency);
            }
            if (retailPrice) {
                this.retailCurrency = retailPrice.currency;
                line.retailPrice = this.dimensionConfig._convertToView(retailPrice.amount, retailPrice.currency);
            }

            for (const dimensionKey of this.dimensionConfig.keys()) {

                const dimensionConfig = this.dimensionConfig.get(dimensionKey);

                if (dimensionConfig.hasDimension(line)) {
                    if (line.special) {
                        specialDimensions[dimensionKey] = 1;
                    } else {
                        if (!dimensions[dimensionKey]) {
                            dimensions[dimensionKey] = [];
                        }

                        let [identifier, object] = dimensionConfig.getDimension(line);

                        dimensions[dimensionKey][identifier] = object;
                    }
                }
            }

            if (line.special) {
                specialLines.push(line);
            } else {
                lines.push(line);
            }
        }

        this.specialDimensions = Object.keys(specialDimensions);
        let dimensionValues = {};

        for (let dimensionKey in dimensions) {
            dimensionValues[dimensionKey] = Object.values(dimensions[dimensionKey]);
        }

        this.dimensions = dimensionValues;
        this.lines = lines;
        this.specialLines = specialLines;

        this.purchasePriceMode = this.value?.purchasePriceMode ?? this.purchasePriceMode;
        this.retailPriceMode = this.value?.retailPriceMode ?? this.retailPriceMode;
        this.manualFactor = this.value?.manualFactor;
        this.taxPercentage = this.value?.taxPercentage ?? this.taxPercentage;

        return this._calculateValue();
    }

    _calculateValue() {

        let dimensions = [];
        this.dimensionTypes = [];

        for (let dimensionKey of this.dimensionConfig.keys()) {
            let dimensionData = this.dimensions?.[dimensionKey];
            let dimensionConfig = this.dimensionConfig.get(dimensionKey);

            if (!dimensionData) {
                continue;
            }

            dimensionData = dimensionData.filter(dimensionConfig.hasDimension);

            if (dimensionData.length > 0) {
                if (dimensionConfig.postProcess) {
                    dimensionData = dimensionConfig.postProcess(dimensionData);
                }

                dimensions.push(dimensionData);
                this.dimensionTypes.push({type: dimensionKey, label: dimensionConfig.label});
            }
        }

        let prices = {};

        for (let i = 0; i < this.lines.length; i++) {

            const line = this.lines[i];

            if (line.key) {
                prices[line.key] = line;
            } else {
                //We reuse old key here, if dimension changes, values are kept
                prices[this.dimensionConfig.getKey(this.dimensionTypes, dimensions, line)] = line;
            }
        }

        let product = _.product(...dimensions);
        let lines = [];

        for (let item of product) {

            let labels = [];
            let objects = [];

            for (let i = 0; i < item.length; i++) {

                let dimensionValue = item[i];
                let dimensionConfig = this.dimensionConfig.get(this.dimensionTypes[i].type);

                labels.push(dimensionConfig.valueLabel(dimensionValue));
                objects.push(_.pick(dimensionValue, dimensionConfig.pick));
            }

            let line = Object.assign({}, ...objects);
            let lineKey = this.dimensionConfig.getKey(this.dimensionTypes, dimensions, line);

            if (prices[lineKey] != null) {
                line.purchasePrice = prices[lineKey].purchasePrice;
                line.retailPrice = prices[lineKey].retailPrice;
                line.priceStatus = prices[lineKey].priceStatus;
                line.vacancies = prices[lineKey].vacancies;
            }

            line.key = lineKey;
            line.labels = labels;

            lines.push(line);
        }

        this.lines = lines;

        return this.updateModelValue();
    }

    addSpecialLine() {
        this.specialLines.push({});

        this.updateAndSaveValue();
    }

    duplicateSpecialLine(index) {
        this.specialLines.push(_.cloneDeep(this.specialLines[index]));
    }

    removeSpecialLine(index) {
        this.specialLines.splice(index, 1);

        this.updateAndSaveValue();
    }

    getFields(dimensions)
    {
        let fields = [
            'priceStatus',
            'vacancies'
        ];

        for (let dimensionKey of dimensions) {
            fields.push(...this.dimensionConfig.get(dimensionKey).pick);
        }

        return fields;
    }

    updateAndSaveValue() {
        this.viewChange = true;

        this.value = this.updateModelValue();
    }

    updateModelValue() {

        let fields = this.getFields(Object.keys(this.dimensions));

        let priceLines = _.map(this.lines.filter(line => line.purchasePrice != null || line.retailPrice != null), item => {
            //Calculate price
            const line = _.pick(item, fields);

            if (item.purchasePrice && this.purchasePriceMode !== 'none' && this.purchasePriceMode !== 'factor') {
                line.purchasePrice = {
                    amount: this.dimensionConfig._convertToModel(item.purchasePrice, this.purchaseCurrency),
                    currency: this.purchaseCurrency
                };
            }

            if (item.retailPrice && this.retailPriceMode !== 'none' && this.retailPriceMode !== 'factor') {
                line.retailPrice = {
                    amount: this.dimensionConfig._convertToModel(item.retailPrice , this.retailCurrency),
                    currency: this.retailCurrency
                };
            }

            return line;
        });

        let specialFields = this.getFields(this.specialDimensions);

        let specialPriceLines = _.map(this.specialLines.filter(line => line.purchasePrice != null || line.retailPrice != null), item => {

            //@fixme from - to dimension hack
            let from = null
            let to = null;
            if (item.from && item.to){
                from = item.from;
                to = item.to;
            }

            const line = _.pick(item, specialFields);

                //Calculate price
            if (item.purchasePrice && this.purchasePriceMode !== 'none' && this.purchasePriceMode !== 'factor') {
                line.purchasePrice = {
                    amount: this.dimensionConfig._convertToModel(item.purchasePrice, this.purchaseCurrency),
                    currency: this.purchaseCurrency
                };
            }

            if (item.retailPrice && this.retailPriceMode !== 'none' && this.retailPriceMode !== 'factor') {
                line.retailPrice = {
                    amount: this.dimensionConfig._convertToModel(item.retailPrice, this.retailCurrency),
                    currency: this.retailCurrency
                };
            }

            line.special = true;
            line.from = from;
            line.to = to;

            return line;
        });

        let cloneLines = [];

        for (let specialPriceLine of specialPriceLines) {
            cloneLines.push(specialPriceLine);
        }

        for (let priceLine of priceLines) {

            let linesToAdd = [
                Object.assign({}, priceLine),
            ];

            if (priceLine.dates) {

                let newLinesToAdd = [];

                for (let lineToAdd of linesToAdd) {

                    delete lineToAdd.dates;

                    for (let date of priceLine.dates) {

                        newLinesToAdd.push(Object.assign({}, lineToAdd, date));
                    }
                }

                linesToAdd = newLinesToAdd;
            }

            if (priceLine.times) {

                let newLinesToAdd = [];

                for (let lineToAdd of linesToAdd) {
                    delete lineToAdd.times;

                    for (let time of priceLine.times) {
                        newLinesToAdd.push(Object.assign({}, lineToAdd, time));
                    }
                }

                linesToAdd = newLinesToAdd;
            }

            for (let lineToAdd of linesToAdd) {
                cloneLines.push(lineToAdd);
            }
        }

        return {
            priceLines: cloneLines,
            manualFactor: this.manualFactor,
            purchasePriceMode: this.purchasePriceMode,
            retailPriceMode: this.retailPriceMode,
            taxPercentage: this.taxPercentage,
        };
    }

    editDimensions() {
        this.dialogService.open({
            viewModel: EditDimensionsDialog,
            model: {
                context: this.context,
                predefinedSeasons: this.predefinedSeasons,
                dimensions: this.dimensions,
            }
        }).whenClosed(response => {
            if (response.wasCancelled) {
                return;
            }

            this.viewChange = true;
            this.dimensions = response.output.dimensions;
            this.value = this._calculateValue();
        });
    }

    changePrice() {
        this.dialogService.open({
            viewModel: ChangePriceDialog
        }).whenClosed(response => {
            if (response.wasCancelled) {
                return;
            }

            for (let line of this.lines) {

                if (line.purchasePrice) {
                    line.purchasePrice = this.increaseValue(line.purchasePrice, response.output.factor, response.output.money);
                }

                if (line.retailPrice) {
                    line.retailPrice = this.increaseValue(line.retailPrice, response.output.factor, response.output.money);
                }
            }

            for (let line of this.specialLines) {

                if (line.purchasePrice) {
                    line.purchasePrice = this.increaseValue(line.purchasePrice, response.output.factor, response.output.money);
                }

                if (line.retailPrice) {
                    line.retailPrice = this.increaseValue(line.retailPrice, response.output.factor, response.output.money);
                }
            }

            this.updateAndSaveValue();
        });
    }

    changeSeason() {
        this.dialogService.open({
            viewModel: ChangeSeasonDialog,
            model: {
                showStatus: this.showStatus
            }
        }).whenClosed(response => {
            if (response.wasCancelled) {
                return;
            }

            if (this.dimensions.date) {

                for (let dimension of this.dimensions.date) {

                    dimension.from = this.increaseSeason(dimension.from, response.output.year, response.output.day);
                    dimension.to = this.increaseSeason(dimension.to, response.output.year, response.output.day);
                }
            }

            for (let line of this.lines) {

                line.priceStatus = response.output.newStatus;
            }

            this.viewChange = true;
            this.value = this._calculateValue();
        });
    }

    setColumnsFromCalculation() {

        let newDateDimension = [];

        for (let calculationColumn of this.calculationColumns) {
            newDateDimension.push({
                from: calculationColumn[0],
                to: calculationColumn[1],
            })
        }

        this.viewChange = true;
        this.dimensions.date = newDateDimension;
        this.value = this._calculateValue();
    }

    increaseSeason(date, year, day) {
        if (year) {
            return moment(date).add(year, 'years').format('YYYY-MM-DD');
        } else if (day) {
            return moment(date).add(day, 'days').format('YYYY-MM-DD');
        }

        return date;
    }

    increaseValue(number, factor, money) {
        if (factor) {
            return (parseFloat(number) * (factor + 1)).toFixed(2);
        } else if (money) {
            return parseFloat(number) + parseFloat(money);
        }

        return number;
    }


    isVisible(priceMode, otherPriceMode)
    {
        if (priceMode === 'factor' && !this.showManualFactorField) {
            return false;
        }

        if (priceMode === 'perRoom' && (!this.dimensionConfig.isVisible('roomCategory', this.context) && !this.dimensionConfig.isVisible('cabineCategory', this.context))) {
            return false;
        }

        if (priceMode === 'none' && (otherPriceMode === 'factor' || otherPriceMode === 'none')) {
            return false;
        }

        if (priceMode === 'factor' && (otherPriceMode === 'factor' || otherPriceMode === 'none')) {
            return false;
        }

        return true;
    }

    setRetailPriceMode(mode)
    {
        this.retailPriceMode = mode;

        this.updateAndSaveValue();
    }

    setPurchasePriceMode(mode)
    {
        this.purchasePriceMode = mode;

        this.updateAndSaveValue();
    }
}
